<template>
  <v-container id="home_menu" fluid tag="section">
    <v-alert
      icon="mdi-shield-lock-outline"
      prominent
      text
      type="info"
      border="left"
      colored-border
      elevation="2"
      class="pa-10"
    >
      <v-row no-gutters>
        <v-col cols="12" md="6">
          <div>
            <h2>ผู้ใช้งานระบบ : {{ user.user_ID }}</h2>
          </div>
          <div>
            <h3>
              ชื่อผู้ใช้งาน : {{ user.user_firstname }}
              {{ user.user_lastname || "" }}
            </h3>
          </div>
          <h3>สถานะ : {{ user.user_status }}</h3>
        </v-col>
        <v-col cols="12" md="6">
          <div align="right">
            <v-btn
              class="mx-2"
              fab
              dark
              color="warning"
              outlined
              @click.native="userUpdate()"
            >
              <v-icon dark>
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-btn
              v-if="user.userauthorityAdmin === '1'"
              class="mx-2"
              fab
              dark
              color="warning"
              outlined
              @click="endCalendar()"
            >
              <v-icon dark>
                mdi-calendar
              </v-icon>
            </v-btn>


            <v-btn
              v-if="user.userauthorityAdmin === '1'"
              class="mx-2"
              fab
              dark
              color="warning"
              outlined
              @click="clearUpdate()"
            >
              <v-icon dark>
                mdi-update
              </v-icon>
            </v-btn>


            <v-btn
              v-if="user.userauthorityAdmin === '1'"
              class="mx-2"
              fab
              dark
              color="warning"
              outlined
              to="/admin/userAdmin"
            >
              <v-icon dark>
                mdi-account-convert
              </v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-alert>

    <v-row no-gutters>
      <v-col cols="12" md="12">
        <AlertAssistant_teacher
          v-if="user.userauthorityAssitantTeach === '1'"
        />
      </v-col>
      <v-col cols="12" md="12">
        <AlertAssistant_teacher2 />
      </v-col>
      <v-col
        cols="12"
        md="12"
        class="pa-2"
        v-if="user.userauthorityAdmin === '1'"
      >
        <div class="text-center">
          <v-btn rounded color="blue" href="#manpowers">งานอัตรากำลัง</v-btn>

          <v-btn rounded color="error darken-2" href="#move38"
            >ย้าย 38 ค.(2)</v-btn
          >
          <v-btn rounded color="pink" href="#moveTeach">ย้ายครู</v-btn>
          <v-btn rounded color="purple" href="#moveDirector"
            >ย้ายผู้บริหาร</v-btn
          >

          <v-btn rounded color="green" href="#regisSedirector"
            >คัดเลือก รอง ผอ.</v-btn
          >

          <v-btn rounded color="green darken-4" href="#regisdirector"
            >คัดเลือก ผอ.</v-btn
          >

          <v-btn rounded color="warning" href="#assistantTeach"
            >ครูผู้ช่วย</v-btn
          >
          <v-btn rounded color="brown" href="#newsanddoc">ข่าวสาร</v-btn>
        </div></v-col
      >
      <v-col cols="12" md="12" v-if="user.userauthorityAdmin === '1'">
        <v-card outlined>
          <v-alert
            text
            color="teal"
            icon="mdi-clock-fast"
            border="left"
            class="mb-0"
          >
            <h2>ส่วนหลักของระบบ</h2>
          </v-alert>

          <v-row no-gutters>
            <v-col
              cols="12"
              md="4"
              v-if="user.userauthorityAdmin === '1'"
              class="pa-1"
            >
              <v-card tile outlined>
                <v-toolbar color="teal" dark>
                  <v-toolbar-title>
                    <v-icon>mdi-numeric-1-circle</v-icon>
                    ข้อมูลสถานศึกษา</v-toolbar-title
                  >
                </v-toolbar>

                <v-list>
                  <v-list-item-group>
                    <v-list-item
                      v-for="(item, i) in college_items"
                      :key="i"
                      :to="item.to"
                    >
                      <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              md="4"
              v-if="user.userauthorityAdmin === '1'"
              class="pa-1"
            >
              <v-card tile outlined>
                <v-toolbar color="teal" dark>
                  <v-toolbar-title
                    ><v-icon>mdi-numeric-2-circle</v-icon
                    >ผู้ใช้งานระบบ</v-toolbar-title
                  >
                </v-toolbar>

                <v-list>
                  <v-list-item-group>
                    <v-list-item
                      v-for="(item, i) in user_system_items"
                      :key="i"
                      :to="item.to"
                    >
                      <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              md="4"
              v-if="user.userauthorityAdmin === '1'"
              class="pa-1"
            >
              <v-card tile outlined>
                <v-toolbar color="teal" dark>
                  <v-toolbar-title
                    ><v-icon>mdi-numeric-3-circle</v-icon>
                    ข้อมูลงานบุคคล</v-toolbar-title
                  >
                </v-toolbar>

                <v-list>
                  <v-list-item-group>
                    <v-list-item
                      v-for="(item, i) in user_items"
                      :key="i"
                      :to="item.to"
                    >
                      <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col> </v-row
        ></v-card>
      </v-col>

      <v-col cols="12" md="12" v-if="user.userauthorityAdmin === '1'"> </v-col>
      <v-row no-gutters>
        <v-col
          cols="12"
          md="12"
          v-if="user.userauthorityAdmin === '1'"
          class="pa-1"
        >
          <v-card tile outlined>
            <v-alert
              text
              dense
              color="indigo"
              icon="mdi-clock-fast"
              border="left"
              class="mb-0"
            >
              <h2>สาขาวิชา</h2>
            </v-alert>
       
        
            <v-list>
              <v-list-item-group>
                <!-- <v-list-item v-for="(item, i) in prepare_items" :key="i" :to="item.to"> -->
                <v-list-item
                  v-for="(item, i) in about_brach"
                  :key="i"
                  :to="item.to"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
      </v-row>

     
      <v-col
        cols="12"
        md="12"
        v-if="user.userauthorityManpower === '1'"
        class="pa-1"
      >
       

        <v-card outlined>
          <v-alert
          text
          color="blue"
          icon="mdi-clock-fast"
          border="left"
          class="mb-0"
        >
          <h2 id="manpowers">งานอัตรากำลัง</h2>
        </v-alert>

          <v-row no-gutters>
            <v-col
              cols="12"
              md="6"
              v-if="user.userauthorityManpower === '1'"
              class="pa-1"
            >
              <v-card tile outlined>
                <v-toolbar color="blue" dark>
                  <v-toolbar-title
                    ><v-icon>mdi-numeric-1-circle</v-icon
                    >ระบบอัตรากำลัง รายงานสถานศึกษา</v-toolbar-title
                  >
                </v-toolbar>

             
                <v-list>
                  <v-list-item-group>
                    <v-list-item
                      v-for="(item, i) in human_items"
                      :key="i"
                      :to="item.to"
                    >
                      <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              md="6"
              v-if="user.userauthorityManpower === '1'"
              class="pa-1"
            >
              <v-card tile outlined>

                <v-toolbar color="blue" dark>
                  <v-toolbar-title
                    ><v-icon>mdi-numeric-2-circle</v-icon
                    >ระบบอัตรากำลัง พิจารณาย้าย</v-toolbar-title
                  >
                </v-toolbar>

               
                <v-list>
                  <v-list-item-group>
                    <v-list-item
                      v-for="(item, i) in manpower_move"
                      :key="i"
                      :to="item.to"
                    >
                      <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-card>

      </v-col>



      <v-col
        cols="12"
        md="12"
        v-if="user.userauthorityManpower === '1'"
        class="pa-1"
      >
       

        <v-card outlined>
          <v-alert
          text
          color="blue"
          icon="mdi-clock-fast"
          border="left"
          class="mb-0"
        >
          <h2 id="manpowers">งานสำรวจสาขาวิชาตำแหน่งว่าง</h2>
        </v-alert>

          <v-row no-gutters>
            <v-col
              cols="12"
              md="12"
              v-if="user.userauthorityManpower === '1'"
              class="pa-1"
            >
           
                <v-list>
                  <v-list-item-group>
                    <v-list-item
                      v-for="(item, i) in humanreport_items"
                      :key="i"
                      :to="item.to"
                    >
                      <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
            
            </v-col>      
          </v-row>
        </v-card>
        
      </v-col>



      <v-col
        cols="12"
        md="12"
        v-if="user.userauthorityMoveTeach === '1'"
        class="pa-1"
      >
        <v-card tile outlined>
          <v-alert
          text
          color="error darken-2"
          icon="mdi-clock-fast"
          border="left"
          class="mb-0"
        >
          <h2 id="move38">
            ระบบการย้าย <span class="red--text" id="fontsizest">บุคลากรทางการศึกษาอื่นตามมาตรา 38 ค.(2)</span>
          </h2>      
        </v-alert>        
          
          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="(item, i) in movement38_items"
                :key="i"
                :to="item.to"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>

      <v-col cols="12" md="12" v-if="user.userauthorityMoveTeach === '1'">
    

        <v-card outlined>
          <v-alert
          text
          color="pink"
          icon="mdi-clock-fast"
          border="left"
          class="mb-0"
        >
          <h2 id="moveTeach">
            ย้าย <span class="red--text" id="fontsizest">สายการสอน</span>
          </h2>   
        </v-alert>

          <v-row no-gutters>
            <v-col
              cols="12"
              md="4"
              v-if="user.userauthorityMoveTeach === '1'"
              class="pa-1"
            >
              <v-card tile outlined>            

                <v-toolbar color="pink" dark>
                  <v-toolbar-title
                    ><v-icon>mdi-numeric-1-circle</v-icon
                    >ระบบการย้ายข้าราชการครู สายการสอน</v-toolbar-title
                  >
                </v-toolbar>


                <v-list>
                  <v-list-item-group>
                    <v-list-item
                      v-for="(item, i) in movement_items"
                      :key="i"
                      :to="item.to"
                    >
                      <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              md="4"
              v-if="user.userauthorityMoveTeach === '1'"
              class="pa-1"
            >
              <v-card tile outlined>
                <v-toolbar color="pink" dark>
                  <v-toolbar-title
                    ><v-icon>mdi-numeric-2-circle</v-icon
                    >เงือนไขสาขาวิชาสถานศึกษา</v-toolbar-title
                  >
                </v-toolbar>


             
                <v-list>
                  <v-list-item-group>
                    <v-list-item
                      v-for="(item, i) in condition_items"
                      :key="i"
                      :to="item.to"
                    >
                      <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>

              <v-card tile outlined>
                <v-toolbar color="pink" dark>
                  <v-toolbar-title
                    ><v-icon>mdi-numeric-3-circle</v-icon
                    >คณะกรรมการ สอจ.</v-toolbar-title
                  >
                </v-toolbar>             
                <v-list>
                  <v-list-item-group>
                    <v-list-item
                      v-for="(item, i) in commiteevecpro"
                      :key="i"
                      :to="item.to"
                    >
                      <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              md="4"
              v-if="user.userauthorityMoveTeach === '1'"
              class="pa-1"
            >
              <v-card tile outlined>

                <v-toolbar color="pink" dark>
                  <v-toolbar-title
                    ><v-icon>mdi-numeric-4-circle</v-icon
                    >หนังสือราชการ</v-toolbar-title
                  >
                </v-toolbar>  

                <v-list>
                  <v-list-item-group>
                    <v-list-item
                      v-for="(item, i) in official_book"
                      :key="i"
                      :to="item.to"
                    >
                      <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

     
      <v-col cols="12" md="12" v-if="user.userauthorityMoveManage === '1'">
        <v-card tile outlined>
          <v-alert
          text
          color="purple"
          icon="mdi-clock-fast"
          border="left"
          class="mb-0"
        >
          <h2 id="moveDirector">
            ย้าย
            <span class="red--text" id="fontsizest">สายงานบริหารสถานศึกษา</span>
          </h2>
        </v-alert>

    
          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="(item, i) in movement_manage_items"
                :key="i"
                :to="item.to"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        md="12"
        v-if="user.userauthorityAssessSedirector === '1'"
      >
 

        <v-card outlined>
          <v-alert
          text
          color="green"
          icon="mdi-clock-fast"
          border="left"
          class="mb-0"
        >
          <h2 id="regisSedirector">
            คัดเลือก ตำแหน่ง รองผู้อำนวยการวิทยาลัย
          </h2>
        </v-alert>

          <v-row no-gutters>
            <v-col
              cols="12"
              md="6"
              v-if="user.userauthorityAssessSedirector === '1'"
              class="pa-1"
            >
              <v-card tile outlined>
                <v-toolbar color="green" dark>
                  <v-toolbar-title
                    ><v-icon>mdi-numeric-1-circle</v-icon
                    >ระบบสมัครสอบตำแหน่ง รองผู้อำนวยการวิทยาลัย</v-toolbar-title
                  >
                </v-toolbar>                
                <v-list>
                  <v-list-item-group>
                    <v-list-item
                      v-for="(item, i) in appoint_sedirector"
                      :key="i"
                      :to="item.to"
                    >
                      <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              md="6"
              v-if="user.userauthorityAssessSedirector === '1'"
              class="pa-1"
            >
              <v-card tile outlined>
                <v-toolbar color="green" dark>
                  <v-toolbar-title
                    ><v-icon>mdi-numeric-2-circle</v-icon
                    >ระบบสมัครสอบตำแหน่ง รองผู้อำนวยการวิทยาลัย</v-toolbar-title
                  >
                </v-toolbar> 
             
                <v-list>
                  <v-list-item-group>
                    <v-list-item
                      v-for="(item, i) in appoint_sedirector2"
                      :key="i"
                      :to="item.to"
                    >
                      <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        md="12"
        v-if="user.userauthorityAssessSedirector === '1'"
      >
  

        <v-card outlined>
          <v-alert
          text
          color="green darken-4"
          icon="mdi-clock-fast"
          border="left"
          class="mb-0"
        >
          <h2 id="regisdirector">
            คัดเลือก ตำแหน่ง ผู้อำนวยการวิทยาลัย
          </h2>
        </v-alert>

          <v-row no-gutters>
            <v-col
              cols="12"
              md="6"
              v-if="user.userauthorityAssessSedirector === '1'"
              class="pa-1"
            >
              <v-card tile outlined>               
                <v-toolbar color="green darken-4" dark>
                  <v-toolbar-title
                    ><v-icon>mdi-numeric-1-circle</v-icon
                    >ระบบสมัครสอบตำแหน่ง ผู้อำนวยการวิทยาลัย</v-toolbar-title
                  >
                </v-toolbar>


              
                <v-list>
                  <v-list-item-group>
                    <v-list-item
                      v-for="(item, i) in appoint_director"
                      :key="i"
                      :to="item.to"
                    >
                      <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              md="6"
              v-if="user.userauthorityAssessSedirector === '1'"
              class="pa-1"
            >
              <v-card tile outlined>
                <v-toolbar color="green darken-4" dark>
                  <v-toolbar-title
                    ><v-icon>mdi-numeric-2-circle</v-icon
                    >ระบบสมัครสอบตำแหน่ง ผู้อำนวยการวิทยาลัย</v-toolbar-title
                  >
                </v-toolbar>


             
                <v-list>
                  <v-list-item-group>
                    <v-list-item
                      v-for="(item, i) in appoint_director2"
                      :key="i"
                      :to="item.to"
                    >
                      <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" md="12" v-if="user.userauthorityAssitantTeach === '1'">
       
        <v-card outlined>
          <v-alert
          text
          color="orange"
          icon="mdi-clock-fast"
          border="left"
          class="mb-0"
        >
          <h2 id="assistantTeach">ครูผู้ช่วย</h2>
        </v-alert>


          <v-row no-gutters>
            <v-col
              cols="12"
              md="6"
              v-if="user.userauthorityAssitantTeach === '1'"
              class="pa-1"
            >
            
                <v-card tile outlined>
                <v-toolbar color="orange" dark>
                  <v-toolbar-title
                    ><v-icon>mdi-numeric-1-circle</v-icon
                    >ระบบครูผู้ช่วย (เรียกรายงานตัวครูผู้ช่วย)</v-toolbar-title
                  >
                </v-toolbar>

            
                <v-list>
                  <v-list-item-group>
                    <v-list-item
                      v-for="(item, i) in prepare_items"
                      :key="i"
                      :to="item.to"
                    >
                      <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              md="6"
              v-if="user.userauthorityAssitantTeach === '1'"
              class="pa-1"
            >
              <v-card tile outlined>
                <v-toolbar color="orange" dark>
                  <v-toolbar-title
                    ><v-icon>mdi-numeric-2-circle</v-icon
                    >ระบบประเมินครูผู้ช่วย</v-toolbar-title
                  >
                </v-toolbar>

             
                <v-list>
                  <v-list-item-group>
                    <v-list-item
                      v-for="(item, i) in prepare_items_committee"
                      :key="i"
                      :to="item.to"
                    >
                      <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" md="12" class="pa-1">
        <v-card tile outlined>
          <v-alert
          text
          color="brown"
          icon="mdi-clock-fast"
          border="left"
          class="mb-0"
        >
          <h2 id="newsanddoc">ข่าวสารและคู่มือระบบ</h2>
        </v-alert>

         
          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="(item, i) in news_manual"
                :key="i"
                :to="item.to"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                  <!--      <v-list-item-title v-text="item.to"></v-list-item-title> -->
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <!-- V-model userdialog -->
    <v-layout row justify-center>
      <v-dialog v-model="edituserdialog" persistent max-width="80%">
        <v-card class="mx-auto pa-6" elevation="2">
          <base-material-card
            color="yellow"
            icon="mdi-clipboard-text"
            title="เปลี่ยนรหัสผ่าน"
            class="px-5 py-3 text_google"
            elevation="2"
          >
          </base-material-card>
          <v-card-text>
            <v-form ref="edituserform" lazy-validation>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex md12>
                    {{ edituser.user_name }} {{ edituser.user_firstname
                    }}{{ edituser.user_lastname }}
                  </v-flex>
                  <v-flex md6>
                    <v-text-field
                      outlined
                      label="Password"
                      v-model="edituser.user_password"
                      type="password"
                    ></v-text-field>
                  </v-flex>
                  <v-flex md6>
                    <v-text-field
                      outlined
                      label="Confirm Password"
                      v-model="edituser.user_confirmpassword"
                      type="password"
                      required
                      :rules="[v => v == edituser.user_password]"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-divider></v-divider>
                  </v-flex>
                </v-layout>
              </v-container>
              <small>* จำเป็น</small>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn large @click.stop="edituserdialog = false" rounded>
              <v-icon dark>mdi-close</v-icon>ยกเลิก
            </v-btn>
            <v-btn
              large
              color="amber lighten-1"
              @click.stop="edituserSubmit()"
              rounded
            >
              <v-icon dark>mdi-pencil</v-icon>&nbsp;แก้ไข
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>

    <!-- V-model endCalendardialog -->
    <v-layout row justify-center>
      <v-dialog v-model="endCalendardialog" persistent max-width="50%">
        <v-card class="mx-auto pa-6" elevation="2">
          <base-material-card
            color="warning"
            icon="mdi-clipboard-text"
            title="ยกเลิกกำหนดการระบบทั้งหมด"
            class="px-5 py-3 text_google"
            elevation="2"
          >
          </base-material-card>
          <v-card-text>
            <v-form ref="endCalendarform" lazy-validation>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex md12>
                    <v-text-field
                      outlined
                      label="Confirm Password"
                      v-model="confirmPassword"
                      required
                      :rules="[v => v == 'Th07111988@7']"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show1 ? 'text' : 'password'"
                      hint="At least 12 characters"
                      counter
                      @click:append="show1 = !show1"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-divider></v-divider>
                  </v-flex>
                </v-layout>
              </v-container>
              <small>* จำเป็น</small>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="success" @click.stop="endCalendarSubmit()" rounded>
              <v-icon dark>mdi-checkbox-marked-circle-outline</v-icon
              >&nbsp;ยืนยัน
            </v-btn>
            <v-btn fab small @click.stop="endCalendardialog = false" rounded>
              <v-icon dark>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </v-container>
</template>

<script>
import AlertAssistant_teacher from "../../components/admin/alertAssistant_teacher.vue";
import AlertAssistant_teacher2 from "../../components/admin/alertAssistant_teacher2.vue";

export default {
  components: { AlertAssistant_teacher, AlertAssistant_teacher2 },
  data() {
    return {
      ApiKey: "HRvec2021",
      user: {},
      users: [],
      edituser: [],
      edituserdialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      user_system_items: [
        {
          text: "ข้อมูลผู้ใช้งานระบบ ข้าราชการครูและบุคลาการทางการศึกษา",
          icon: "mdi-account-arrow-right",
          to: "/admin/personnel"
        },
        {
          text: "ข้อมูลผู้ใช้งานระบบ วิทยาลัย สอจ.สถานบัน กจ2 Admin",
          icon: "mdi-account-arrow-right",
          to: "/admin/user"
        },
        {
          text: "สถานะผู้ใช้งานระบบ",
          icon: "mdi-account-cog",
          to: "/admin/user_status"
        }
        /*  { text: "ข้อมูลรอบปี", icon: "mdi-calendar", to: "/admin/period" } */
      ],

      college_items: [
        {
          text: "ข้อมูลประเภทสถานศึกษา",
          icon: "mdi-office-building",
          to: "/admin/collegetype"
        },

        {
          text: "ข้อมูลสถาบันการอาชีวศึกษา",
          icon: "mdi-office-building",
          to: "/admin/veivocal"
        },
        {
          text: "ข้อมูลสถานศึกษา",
          icon: "mdi-office-building",
          to: "/admin/college"
        },
        {
          text: "ข้อมูลรายละเอียดสถานศึกษา",
          icon: "mdi-office-building",
          to: "/admin/collegeinfo"
        }
      ],

      period_config: [
        {
          text: "กำหนดการสมัคร ผู้บริหารสถานศึกษา (ผอ. / รอง ผอ.)",
          icon: "mdi-calendar",
          to: "/admin/timelines/examination_deputyDirector"
        },
        {
          text: "กำหนดการครูผู้ช่วย",
          icon: "mdi-calendar",
          to: "/admin/timelines/report_assistantteacher"
        },
        /*  { text: "กำหนดการย้ายข้าราชการครู", icon: "mdi-calendar", to: "/admin/period" }, */
        {
          text: "กำหนดการย้ายข้าราชการครู",
          icon: "mdi-calendar",
          to: "/admin/timelines/transfer_teachers"
        },
        {
          text: "กำหนดการย้ายสายบริหาร",
          icon: "mdi-calendar",
          to: "/admin/timelines/transfer_directors"
        },
        /* {
          text: "กำหนดการสถานศึกษา",
          icon: "mdi-calendar",
          to: "/admin/period_college"
        }, */
        /*  {
          text: "กำหนดการสำนักงานอาชีวศึกษาจังหวัด",
          icon: "mdi-calendar",
          to: "/admin/period_vecprovince"
        }, */
        {
          text: "กำหนดการอัตรากำลัง",
          icon: "mdi-calendar",
          to: "/admin/sys_config_collegemanpower"
        }
      ],

      user_items: [
        {
          text: "จัดการคำนำหน้าชื่อ",
          icon: "mdi-format-title",
          to: "/admin/prefix"
        },
        {
          text: "ข้าราชการครูและบุคลากร (หลัก)",
          icon: "mdi-account",
          to: "/admin/personnel_tem"
        },

        {
          text: "บุคลากร งานอัตรากำลัง (รอง)",
          icon: "mdi-account",
          to: "/admin/personnel_etc"
        },


        {
          text: "ข้อมูลขอรับข้อมูลบุคคล",
          icon: "mdi-account-convert",
          to: "/admin/collegepersonnelreq"
        },

        {
          text: "ข้อมูลคุณวุฒิการศึกษา",
          icon: "mdi-school",
          to: "/admin/personnel_education"
        },
        {
          text: "ข้อมูลประสบการณ์",
          icon: "mdi-airplane",
          to: "/admin/personnel_experience"
        },
        {
          text: "ข้อมูลประวัติการรับราชการ",
          icon: "mdi-access-point-network",
          to: "/admin/personnel_work_history"
        },
        {
          text: "ข้อมูลผลงาน รางวัล",
          icon: "mdi-trophy-award",
          to: "/admin/personnel_award"
        }
        /*  {
          text: "ข้อมูลเกี่ยวกับรายงานตัวครูผู้ช่วย",
          icon: "mdi-account",
          to: "/admin/assistantteach"
        }, */

        /* } */
        /*   {
          text: "ข้อมูลโทษ วินัย",
          icon: "mdi-alarm-light",
          to: "/admin/personnel_discipline"
        } */
      ],

      about_brach: [
        {
          text: "สาขาวิชาเอก งานย้ายสายการสอน",
          icon: "mdi-source-branch",
          to: "/admin/branch"
        },
        {
          text: "สาขางาน งานย้ายสายการสอน",
          icon: "mdi-source-branch",
          to: "/admin/branch_sub"
        },
        {
          text: "ประเภทวิชา",
          icon: "mdi-source-branch",
          to: "/admin/subject_type"
        },
        {
          text: "สาขาวิชา/สาขางาน",
          icon: "mdi-source-branch",
          to: "/admin/rate_work_course_std"
        },
        {
          text: "หลักสูตรวิชาชีพระยะสั้น",
          icon: "mdi-source-branch",
          to: "/admin/short_course"
        }
      ],

      movement38_items: [
        {
          text: "กำหนดการย้าย 38 ค.(2)",
          icon: "mdi-calendar",
          to: "/admin/timelines/transfer_38"
        },

        {
          text: "ตำแหน่งว่าง",
          icon: "mdi-calendar",
          to: "/admin/man_power_38"
        },

        {
          text: "ย้าย 38 ค.(2)",
          icon: "mdi-calculator",
          to: "/admin/transference_personnel_38"
        }

        /*  {
          text: "การรายงานตัว ปลายทาง",
          icon: "mdi-calculator",
          to: "/admin/conditons_transfer_success_reportmv_38"
        } */
      ],

      movement_items: [
        {
          text: "กำหนดการย้ายครู",
          icon: "mdi-calendar",
          to: "/admin/timelines/transfer_teachers"
        },
        {
          text: "ย้ายครู ประกอบการพิจารณา",
          icon: "mdi-account-switch",
          to: "/admin/transference_location_detail"
        },
        {
          text: "ย้ายครู รายละเอียด",
          icon: "mdi-account-switch",
          to: "/admin/transference_location"
        },
        {
          text: "ย้ายครู [ประมวลผลแบบ 1]",
          icon: "mdi-calculator",
          to: "/admin/transference_personnel"
        },
        {
          text: "ย้ายครู [กรณีพิเศษ]",
          icon: "mdi-calculator",
          to: "/admin/transference_personnel_sp"
        },
        {
          text: "ประมวลผลการย้าย [ประมวลผลแบบ 2]",
          icon: "mdi-calculator",
          to: "/admin/process_transfer"
        },
        {
          text: "ประมวลผลการย้าย สับเปลี่ยนแบบปกติ แบบที่ 3]",
          icon: "mdi-calculator",
          to: "/admin/process_transfer_switch_normal"
        },
        {
          text: "ประมวลผลการย้าย [ประมวลผลแบบ 4 สับเปลี่ยน]",
          icon: "mdi-calculator",
          to: "/admin/process_transfer_switch"
        },
        {
          text: "สรุปผลการย้าย",
          icon: "mdi-newspaper",
          to: "/admin/conditons_transfer_success"
        },
        {
          text: "การรายงานตัว ณ วิทยาลัย",
          icon: "mdi-calculator",
          to: "/admin/conditons_transfer_success_reportmv"
        }
      ],

      movement_manage_items: [
        {
          text: "กำหนดการ ย้ายสายบริหาร",
          icon: "mdi-calendar",
          to: "/admin/timelines/transfer_directors"
        },
        {
          text: "สายงานบริหารสถานศึกษา",
          icon: "mdi-account-switch",
          to: "/admin/transference_manage_main"
        },
        {
          text: "สายงานบริหารสถานศึกษา ประกอบการพิจารณา",
          icon: "mdi-account-switch",
          to: "/admin/transference_manage_location_detail"
        },
        {
          text: "สายงานบริหารสถานศึกษา รายละเอียด",
          icon: "mdi-account-switch",
          to: "/admin/transference_manage_location"
        },
        {
          text: "สายงานบริหารสถานศึกษา [ประมวลผลแบบ 1]",
          icon: "mdi-calculator",
          to: "/admin/transference_manage"
        }
      ],

      condition_items: [
        {
          text: "เงือนไขการรับย้ายของสถานศึกษา",
          icon: "mdi-checkbox-multiple-marked-outline",
          to: "/admin/conditions_transfer"
        },
        {
          text: "เงือนไขการรับย้ายสาขาวิชา",
          icon: "mdi-checkbox-multiple-marked-outline",
          to: "/admin/conditions_branch"
        }
      ],

      commiteevecpro: [
        {
          text: "รายงานคณะกรรมการ สอจ.",
          icon: "mdi-checkbox-multiple-marked-outline",
          to: "/admin/commiteevecreport"
        },
        {
          text: "รายชื่อกรรมการ สอจ.",
          icon: "mdi-checkbox-multiple-marked-outline",
          to: "/admin/commiteevecpro"
        }
      ],

      official_book: [
        {
          text: "คำสั่งแต่งตั้งรักษาการในตำแหน่ง",
          icon: "mdi-bookmark-check",
          to: "/admin/Order_appoint"
        },
        {
          text: "ศูนย์กลางส่งออกเอกสาร ประกอบการพิจารณาย้าย",
          icon: "mdi-bookmark-check",
          to: "/admin/transference_export_report"
        }
      ],

      manpower_move: [
        {
          text: "ข้อมูลอัตรากำลังสถานศึกษา สำหรับย้าย",
          icon: "mdi-account-multiple",
          to: "/admin/man_power"
        },
        {
          text: "ข้อมูลอัตรากำลัง จ.18",
          icon: "mdi-account-multiple",
          to: "/admin/jor18"
        }
      ],
      human_items: [
        {
          text: "กำหนดการ รายงานอัตรากำลัง",
          icon: "mdi-calendar",
          to: "/admin/sys_config_collegemanpower"
        },

        {
          text: "อัตรากำลังสถานศึกษา",
          icon: "mdi-account-multiple",
          to: "/admin/personnel_etc"
        },

        {
          text: "ข้อมูลลูกจ้างชั่วคราว",
          icon: "mdi-account-multiple",
          to: "/admin/personnel_etc_tem"
        },

        {
          text: "รายงานอัตรากำลังประจำปี สถานศึกษา",
          icon: "mdi-account-multiple",
          to: "/admin/rate_work_g"
        },
        {
          text: "จำนวนนักเรียน ศธ.02",
          icon: "mdi-account-multiple",
          to: "/admin/power_rate/rate_work_college"
        }
        /*  {
          text: "รายงานอัตราสถานศึกษาไม่ตรง ศธ.02",
          icon: "mdi-account-multiple",
          to: "/admin/college_updateedit"
        } */
      ],


      humanreport_items: [
        {
          text: "กำหนดการ",
          icon: "mdi-calendar",
          to: "/admin/sys_config_collegemanpower_report"
        },

        {
          text: "จัดการเลขที่ตำแหน่งว่าง",
          icon: "mdi-account-multiple",
          to: "/admin/jor18"
        },

        {
          text: "สถานะการรายงาน",
          icon: "mdi-account-multiple",
          to: "/admin/jor18_report"
        },

        {
          text: "การรายงาน (เลขที่ตำแหน่ง)",
          icon: "mdi-account-multiple",
          to: "/admin/college_manpower"
        },


     
      ],


      prepare_items: [
        {
          text: "กำหนดการ ครูผู้ช่วย",
          icon: "mdi-calendar",
          to: "/admin/timelines/report_assistantteacher"
        },
        {
          text: "เรียกรายงานตัวครูผู้ช่วย/รอบ",
          icon: "mdi-account-settings",
          to: "/admin/assistant_teacher_report/manage_assistant_teacher"
        },
        {
          text: "ข้อมูลเลขที่ตำแหน่ง วิทยาลัย ใช้รายงานตัว",
          icon: "mdi-account-settings",
          to: "/admin/assistant_teacher_report/manage_college_manpower"
        },
        {
          text: "การรายงานตัวเลือกสถานศึกษา",
          icon: "mdi-account-star",
          to: "/admin/assistant_teacher_report/teacher_college_manpower_report"
        },
        {
          text: "การรายงานตัว ณ สถานศึกษา",
          icon: "mdi-account-star",
          to: "/admin/assistant_teacher_report/manage_assistant_CollegeReport"
        },
        {
          text: "การรายงานตัว ณ สถานศึกษา(โอนย้าย)",
          icon: "mdi-account-star",
          to:
            "/admin/assistant_teacher_report/manage_assistant_CollegeReportTran"
        }
      ],
      prepare_items_committee: [
        {
          text: "รอบบรรจุ",
          icon: "mdi-calendar",
          to:
            "/admin/assistant_teacher_report/personnel_temporary_assistant_period"
        },
        {
          text: "เสนอคณะกรรมการ",
          icon: "mdi-account-settings",
          to:
            "/admin/assistant_teacher_report/manage_assistant_teacher_committee"
        },

        {
          text: "การแต่งตั้งคณะกรรมการ",
          icon: "mdi-account-star",
          to: "/admin/assistant_teacher"
        },
        {
          text: "การประเมิน/ตรวจสอบ",
          icon: "mdi-account-star",
          to: "/admin/assistant_teacher_detail"
        },
        {
          text: "รายงานผลประเมิน(ครบ)",
          icon: "mdi-account-star",
          to: "/admin/assistant_teacher_report"
        }
      ],

      news_manual: [
        { text: "ข่าวสาร", icon: "mdi-book", to: "/admin/news_s" },
        {
          text: "คู่มือใช้งาน",
          icon: "mdi-book-open-page-variant",
          to: "/admin/manual_s"
        }
      ],
      appoint_sedirector: [
        {
          text: "กำหนดการ คัดเลือก รองผู้อำนวยการวิทยาลัย",
          icon: "mdi-calendar",
          to: "/admin/timelines/examination_deputyDirector"
        },
        { text: "ผู้สมัคร", icon: "mdi-book", to: "/admin/sedirector_app" },
        {
          text: "ภาค ก.",
          icon: "mdi-book",
          to: "/admin/sedirector_assessment/sedirector_app_a"
        },
        {
          text: "ภาค ข",
          icon: "mdi-book",
          to: "/admin/sedirector_assessment/sedirector_app_b"
        },
        {
          text: "ประกาศผล",
          icon: "mdi-book",
          to: "/admin/sedirector_assessment/sedirector_app_notice"
        }
      ],
      appoint_sedirector2: [
        {
          text: "ตำแหน่งว่างสถานศึกษา",
          icon: "mdi-book",
          to: "/admin/sedirector_assessment/director_college_manpower"
        },
        {
          text: "รายงานตัวเลือกสถานศึกษา",
          icon: "mdi-book",
          to: "/admin/sedirector_assessment/sedirector_app_choose"
        },
        {
          text: "รายงาน ณ สถานศึกษา",
          icon: "mdi-book",
          to: "/admin/sedirector_assessment/sedirector_app_a"
        }
      ],

      appoint_director: [
        {
          text: "กำหนดการ คัดเลือก ผู้อำนวยการวิทยาลัย",
          icon: "mdi-calendar",
          to: "/admin/timelines/examination_Director"
        },
        { text: "ผู้สมัคร", icon: "mdi-book", to: "/admin/director_app" },
        { text: "ภาค ก", icon: "mdi-book", to: "/admin/director_assessment/director_app_a" },
        { text: "ภาค ข", icon: "mdi-book", to: "/admin/director_assessment/director_app_b" },
        { text: "ภาค ค", icon: "mdi-book", to: "/admin/director_assessment/director_app_c" },

      ],

      appoint_director2: [
        {
          text: "ตำแหน่งว่างสถานศึกษา",
          icon: "mdi-book",
          to: "/admin/director_assessment/director_college_manpower"
        },
        {
          text: "รายงานตัวเลือกสถานศึกษา",
          icon: "mdi-book",
          to: "/admin/director_assessment/sedirector_app_choose"
        },
        {
          text: "รายงาน ณ สถานศึกษา",
          icon: "mdi-book",
          to: "/admin/director_assessment/director_app_a"
        }
      ],

      endCalendardialog: false,
      confirmPassword: "",
      show1: false,
      updateSystems: {}
    };
  },

  async mounted() {
    await this.userQueryAll();
  },

  methods: {
    async clearUpdate(){
      Swal.fire({
        title: "Reset For Update",       
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ดำเนินการ",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {         
          let result = await this.$http.post(
            "user.reset.update.php",{
              ApiKey:this.ApiKey
            }
          );
          if (result.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });           
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการซ้ำ",
              showConfirmButton: false,
              timer: 1500
            });
          }        
        }
      });
    },
    async endCalendarSubmit() {
      if (this.$refs.endCalendarform.validate()) {
        this.updateSystems.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "period.update.allsystem.php",
          this.updateSystems
        );

        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการปิดกำหนดการในระบบเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
    },
    async endCalendar() {
      this.endCalendardialog = true;
    },
    async userQueryAll() {
      let result;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      result = await this.$http.post("user.php", {
        ApiKey: this.ApiKey,
        user_ID: userSession.user_ID
      });
      this.user = result.data;
    },
    async userUpdate() {
      let result = await this.$http.post("user.php", {
        ApiKey: this.ApiKey,
        user_ID: this.user.user_ID
      });
      this.edituser = result.data;
      this.edituser.p_word = "";
      this.edituserdialog = true;
    },
    async edituserSubmit() {
      if (this.$refs.edituserform.validate()) {
        this.edituser.ApiKey = this.ApiKey;
        let result = await this.$http.post("user.update.php", this.edituser);
        if (result.data.status == true) {
          this.user = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.userQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.edituserdialog = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#fontsizest {
  font-size: 20pt;
  font-weight: bold;
}
</style>
